import React from "react"
import './Instagram.scss'

const Instagram = () => {

  return (
    <section className="instagram-wrapper">
      <a href="https://www.instagram.com/zinetrip/">☻INSTAGRAM☻</a>
    </section>
  )
}

export default Instagram
